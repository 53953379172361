import {
  Inject,
  Injectable,
}                          from '@angular/core';
import { Store }           from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import {
  ACCESS_TOKEN,
  LOCAL_STORAGE,
}                          from '../app.constants';
import {
  clearAccountUser,
  fetchAccountsWithWarningBadgeCount,
  fetchAccountUser,
  fetchClientBadgeCount,
  fetchNotificationBadgeCount,
  fetchPaymentBadgeCount,
  fetchUnlinkedAccountsBadgeCount,
}                          from '../state/app.actions';
import {
  selectAccountsWithWarningBadgeCount,
  selectAccountUser,
  selectClientBadgeCount,
  selectNotificationBadgeCount,
  selectPaymentBadgeCount,
  selectUnlinkedAccountsBadgeCount,
}                          from '../state/app.selectors';

@Injectable({
  providedIn: 'root',
})
export class MainService {
  accountUser$ = this.store.select(selectAccountUser);
  clientBadgeCount$ = this.store.select(selectClientBadgeCount);
  messageBadgeCount$ = new BehaviorSubject(0); //TODO
  notificationBadgeCount$ = this.store.select(selectNotificationBadgeCount);
  unlinkedAccountBadgeCount$ = this.store.select(selectUnlinkedAccountsBadgeCount);
  accountsWithWarningBadgeCount$ = this.store.select(selectAccountsWithWarningBadgeCount);
  paymentBadgeCount$ = this.store.select(selectPaymentBadgeCount);

  constructor(
    @Inject(LOCAL_STORAGE) private readonly localStorage: Storage,
    private readonly store: Store,
  ) {
  }

  fetchAccountUser(): void {
    this.store.dispatch(fetchAccountUser());
  }

  fetchClientBadgeCount(): void {
    this.store.dispatch(fetchClientBadgeCount());
  }

  fetchNotificationBadgeCount(): void {
    this.store.dispatch(fetchNotificationBadgeCount());
  }

  fetchPaymentBadgeCount(): void {
    this.store.dispatch(fetchPaymentBadgeCount());
  }

  fetchUnlinkedAccountsBadgeCount(): void {
    this.store.dispatch(fetchUnlinkedAccountsBadgeCount());
  }

  fetchAccountsWithWarningBadgeCount(): void {
    this.store.dispatch(fetchAccountsWithWarningBadgeCount());
  }

  logout(): void {
    this.localStorage.removeItem(ACCESS_TOKEN);
    this.store.dispatch(clearAccountUser());
  }
}
