import {
  Directive,
  Input,
  OnDestroy,
  OnInit,
}                           from '@angular/core';
import {
  AbstractControl,
  AsyncValidator,
  ControlValueAccessor,
  FormGroup,
  NonNullableFormBuilder,
  ValidationErrors,
}                           from '@angular/forms';
import {
  filter,
  map,
  Observable,
  of,
  startWith,
  Subject,
  take,
  takeUntil,
}                           from 'rxjs';
import { ValidatorService } from '~app/core/validators';

@Directive()
export abstract class RbForm implements ControlValueAccessor, AsyncValidator, OnDestroy, OnInit {
  @Input()
  errorKey$?: Observable<string | null>;

  form?: FormGroup;

  protected destroy$ = new Subject<void>();

  constructor(protected readonly fb: NonNullableFormBuilder,
              protected readonly validatorService: ValidatorService) {
  }

  ngOnInit() {
    setTimeout(() => {
      this.form?.enable({ onlySelf: true }); // to trigger validation (as result of valueChanges)
      this.form?.markAllAsTouched(); // error state on fields only visible if touched
      this.onTouched(); // inform parent of touched status
    }, 1);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onTouched: () => void = () => {
  };

  registerOnChange(fn: never) {
    this.form?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(fn);
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    if (!this.form || this.form.valid || control.pristine) {
      return of(null);
    }
    return this.form.statusChanges.pipe(
      startWith(this.form.status),
      filter((status) => status !== 'PENDING'),
      take(1),
      map(() => {
        if (!this.form || this.form.valid) {
          return null;
        }
        const errorKey = this.validatorService.firstErrorKey(this.form);
        if (errorKey) {
          return { [errorKey]: true };
        }
        return { error: true };
      }),
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  writeValue(_obj: never): void {
  }
}
