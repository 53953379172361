import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
}                           from '@angular/core';
import {
  NavigationEnd,
  Router,
}                           from '@angular/router';
import {
  combineLatest,
  map,
}                           from 'rxjs';
import { ofType }           from '~utils/rxjs';
import { AppRoute }         from '../app.route.enum';
import { ColorModeService } from '../core';
import { MainService }      from './main.service';
import { SideNav }          from './side-nav';

@Component({
  selector: 'rb-main',
  templateUrl: './main.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainComponent implements OnInit {
  colorMode$ = this.colorModeService.colorMode$;
  user$ = this.mainService.accountUser$;
  showIsabel6Link$ = this.router.events.pipe(
    ofType(NavigationEnd),
    map(({ urlAfterRedirects }) => !!urlAfterRedirects.match(AppRoute.Payments)),
  );

  sideNav = SideNav.build(
    // SideNav.item({ route: AppRoute.Dashboard, icon: 'dashboard' }),
    SideNav.item({
      route: AppRoute.Clients,
      icon: 'clients',
      badge: {
        value: this.mainService.clientBadgeCount$,
      },
    }),
    // SideNav.item({
    //   route: AppRoute.Messages,
    //   icon: 'messages',
    //   badge: {
    //     value: this.mainService.messageBadgeCount$,
    //   },
    // }),
    // SideNav.item({ route: AppRoute.Contacts, icon: 'contacts' }),
    SideNav.item({
      route: AppRoute.BankAccounts,
      icon: 'accounts',
      badge: {
        value: combineLatest([
          this.mainService.unlinkedAccountBadgeCount$,
          this.mainService.accountsWithWarningBadgeCount$,
        ]).pipe(map(([x, y]) => x + y)),
      },
    }),
    SideNav.item({
      route: AppRoute.Payments,
      icon: 'payments',
      badge: {
        value: this.mainService.paymentBadgeCount$,
      },
    }),
    SideNav.item({
      route: AppRoute.Notifications,
      icon: 'notifications',
      badge: {
        value: this.mainService.notificationBadgeCount$,
      },
    }),
    // SideNav.group({ label: 'OTHER', icon: 'more-horizontal' }).add(
    //   SideNav.item({ route: AppRoute.Actions, icon: 'actions' }),
    //   SideNav.item({ route: [AppRoute.Archive, ArchiveRoute.Clients], icon: 'archived-clients' }),
    //   SideNav.item({ route: [AppRoute.Archive, ArchiveRoute.BankAccounts], icon: 'archived-accounts' }),
    // ),
    SideNav.divider(),
    SideNav.item({ route: AppRoute.Settings, icon: 'settings' }),
  );

  constructor(
    private readonly colorModeService: ColorModeService,
    private readonly mainService: MainService,
    private readonly router: Router,
  ) {
  }

  ngOnInit() {
    this.mainService.fetchAccountUser();
    this.mainService.fetchClientBadgeCount();
    this.mainService.fetchNotificationBadgeCount();
    this.mainService.fetchPaymentBadgeCount();
    this.mainService.fetchUnlinkedAccountsBadgeCount();
    this.mainService.fetchAccountsWithWarningBadgeCount();
  }

  onLogoutClick() {
    this.mainService.logout();
    this.router.navigate([AppRoute.Login]);
  }

  setColorMode(colorMode: 'dark' | 'light' | null) {
    this.colorModeService.setColorMode(colorMode);
  }
}
