import {
  createAction,
  props,
} from '@ngrx/store';
import {
  FileExtension,
  ShareResponse,
} from '~domain/enums';
import {
  AccountUser,
  Beneficiary,
  ClientData,
  Office,
  PaymentAccount,
  PaymentData,
  SimpleClient,
} from '~domain/types';

export const fetchColorMode = createAction(
  '[App] fetch color mode',
);
export const fetchColorModeResult = createAction(
  '[App] fetch color mode result',
  props<{
    colorMode: 'dark' | 'light' | null;
  }>(),
);

export const setColorMode = createAction(
  '[App] set color mode', props<{
    colorMode: 'dark' | 'light' | null
  }>());
export const setColorModeResult = createAction(
  '[App] set color mode result',
  props<{
    colorMode: 'dark' | 'light' | null
  }>(),
);

export const fetchAccountUser = createAction(
  '[App] fetch account user',
);
export const fetchAccountUserResult = createAction(
  '[App] fetch account user result',
  props<{
    user: AccountUser
  }>());

export const clearAccountUser = createAction(
  '[App] clear account user',
);

export const fetchClientBadgeCount = createAction(
  '[App] fetch client badge count',
);
export const fetchClientBadgeCountResult = createAction(
  '[App] fetch client badge count result',
  props<{
    count: number;
  }>(),
);

export const fetchNotificationBadgeCount = createAction(
  '[App] fetch notification badge count',
);
export const fetchNotificationBadgeCountResult = createAction(
  '[App] fetch notification badge count result',
  props<{
    count: number;
  }>(),
);

export const fetchPaymentBadgeCount = createAction(
  '[App] fetch payment badge count',
);
export const fetchPaymentBadgeCountResult = createAction(
  '[App] fetch payment badge count result',
  props<{
    count: number;
  }>(),
);

export const fetchUnlinkedAccountsBadgeCount = createAction(
  '[App] fetch unlinked accounts badge count',
);
export const fetchUnlinkedAccountsBadgeCountResult = createAction(
  '[App] fetch unlinked accounts badge count result',
  props<{
    count: number;
  }>(),
);

export const fetchAccountsWithWarningBadgeCount = createAction(
  '[App] fetch accounts with warning badge count',
);
export const fetchAccountsWithWarningBadgeCountResult = createAction(
  '[App] fetch accounts with warning badge count result',
  props<{
    count: number;
  }>(),
);

export const closeDialog = createAction(
  '[App] close dialog',
  props<{
    message?: string;
  }>());

export const fetchOfficeResult = createAction(
  '[App] fetch office result',
  props<{
    office: Office;
  }>());

export const createClient = createAction(
  '[App] create client',
  props<{
    client: ClientData;
  }>());

export const createClientResult = createAction(
  '[App] create client result',
  props<{
    id: string;
    accountId?: string | null;
  }>(),
);

export const createClientError = createAction(
  '[App] create client error',
);

export const respondToDocument = createAction(
  '[ClientNotifications] respond to document',
  props<{
    id: string;
    clientId: string;
    response: ShareResponse;
    message?: string;
  }>(),
);

export const respondToDocumentResult = createAction(
  '[ClientNotifications] respond to document result',
);

export const respondToDocumentError = createAction(
  '[ClientNotifications] respond to document error',
);

export const downloadDocument = createAction(
  '[App] download document',
  props<{
    id: string;
    clientId: string;
    name: string;
    extension: FileExtension;
  }>(),
);

export const downloadDocumentResult = createAction(
  '[App] download document result',
);

export const downloadDocumentError = createAction(
  '[App] download document error',
);

export const fetchInfoPaymentDialog = createAction(
  '[App] fetch info payment dialog',
  props<{
    paymentData: PaymentData,
    reloadPayments?: boolean
  }>(),
);

export const fetchInfoPaymentDialogResult = createAction(
  '[App] fetch info payment dialog result',
  props<{
    paymentData: PaymentData,
    reloadPayments?: boolean,
    paymentAccounts: PaymentAccount[],
    paymentClients: SimpleClient[],
    beneficiaries: Beneficiary[],
  }>(),
);

export const fetchInfoPaymentDialogError = createAction(
  '[App] fetch info payment dialog error',
);

export const addPayment = createAction(
  '[App] add payment',
  props<{
    paymentData: PaymentData,
    anotherPayment: boolean
    reloadPayments?: boolean
  }>(),
);

export const addPaymentResult = createAction(
  '[App] add payment result',
  props<{
    anotherPayment: boolean,
    reloadPayments?: boolean
  }>(),
);

export const addPaymentError = createAction(
  '[App] add payment error',
);

export const fetchBeneficiaries = createAction(
  '[App] fetch beneficiaries',
  props<{
    clientId: string
  }>(),
);

export const fetchBeneficiariesResult = createAction(
  '[App] fetch beneficiaries result',
  props<{
    beneficiaries: Beneficiary[]
  }>(),
);

export const fetchBeneficiariesError = createAction(
  '[App] fetch beneficiaries error',
);

export const updatePayment = createAction(
  '[App] update payment',
  props<{
    paymentData: PaymentData
  }>(),
);

export const updatePaymentResult = createAction(
  '[App] update payment result',
  props<{
    reloadPayments: boolean
  }>(),
);

export const updatePaymentError = createAction(
  '[App] update payment error',
);

export const updateBeneficiary = createAction(
  '[App] update beneficiary',
  props<{
    beneficiaryId: string,
    clientId: string,
    beneficiary: Beneficiary,
  }>(),
);

export const addBeneficiary = createAction(
  '[App] add beneficiary',
  props<{
    clientId: string,
    beneficiary: Beneficiary,
  }>(),
);

export const deleteBeneficiary = createAction(
  '[App] delete beneficiary',
  props<{
    beneficiaryId: string,
    clientId: string,
  }>(),
);

export const editBeneficiariesResult = createAction(
  '[App] edit beneficiaries result',
  props<{
    beneficiaries: Beneficiary[]
  }>(),
);

export const editBeneficiariesError = createAction(
  '[App] edit beneficiaries error',
);
