<div [formGroup]="form" class="grid grid-cols-2 gap-x-[6rem]">
  <div class="col-start-1 grid grid-cols-2 grid-rows-3 gap-x-[1rem] gap-y-[0.5rem]">
    <rb-form-field [label]="'APP.CLIENTS.CLIENT_INFO.PERSONAL_INFO.FIRSTNAME' | translate"
                   [required]="true"
                   class="col-start-1 row-start-1">
      <mat-form-field [class.no-error]="!validatorService.errorMatches(form.controls.firstName, errorKey$ | async)">
        <input (blur)="onTouched()"
               [placeholder]="'APP.CLIENTS.CLIENT_INFO.PERSONAL_INFO.FIRSTNAME' | translate"
               formControlName="firstName"
               matInput
               type="text" />
      </mat-form-field>
    </rb-form-field>
    <rb-form-field [label]="'APP.CLIENTS.CLIENT_INFO.PERSONAL_INFO.LASTNAME' | translate"
                   class="col-start-2 row-start-1">
      <mat-form-field [class.no-error]="!validatorService.errorMatches(form.controls.lastName, errorKey$ | async)">
        <input (blur)="onTouched()"
               [placeholder]="'APP.CLIENTS.CLIENT_INFO.PERSONAL_INFO.LASTNAME' | translate"
               formControlName="lastName"
               matInput
               type="text" />
      </mat-form-field>
    </rb-form-field>
    <rb-form-field [label]="'APP.CLIENTS.CLIENT_INFO.PERSONAL_INFO.SSN' | translate" class="col-start-1 row-start-2">
      <mat-form-field [class.no-error]="!validatorService.errorMatches(form.controls.ssn, errorKey$ | async)">
        <input (blur)="onTouched()"
               [inputMask]="ssnInputMask"
               formControlName="ssn"
               matInput
               placeholder="__.__.__-___.__" />
      </mat-form-field>
    </rb-form-field>
    <rb-form-field [label]="'APP.CLIENTS.CLIENT_INFO.PERSONAL_INFO.BIRTHDATE' | translate"
                   class="col-start-2 row-start-2">
      <mat-form-field [class.no-error]="!validatorService.errorMatches(form.controls.birthDate, errorKey$ | async)">
        <!--
          -- Why a hardcoded placeholder is required --
          In order to restore the placeholder on blur, the input mask requires a pure HTML attribute, so we can't
          use any Angular features like binding or expressions. There's also no option to set the placeholder
          dynamically as the input mask stores this value upon creation.

          -- Why an empty value attribute is required --
          The date picker's validators use an internal _lastValueValid property to determine whether to add a
          validation error. This is default false¹ and only updated upon input value change. Binding the form
          control's initial value doesn't trigger this event, so we provide the initial value again here as a way
          to explicitly trigger it.

          ¹https://github.com/angular/components/blob/main/src/material/datepicker/datepicker-input-base.ts#L239
      -->
        <input (blur)="onTouched()"
               [matDatepicker]="picker"
               [max]="today"
               formControlName="birthDate"
               matInput
               placeholder="dd-mm-yyyy" />
        <mat-datepicker-toggle [for]="picker" matIconSuffix>
          <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </rb-form-field>
    <rb-form-field [label]="'APP.CLIENTS.CLIENT_INFO.PERSONAL_INFO.LANGUAGE' | translate"
                   class="col-start-1 row-start-3">
      <mat-form-field [class.no-error]="!validatorService.errorMatches(form.controls.language, errorKey$ | async)">
        <mat-select formControlName="language">
          <mat-option *ngFor="let option of languages" [value]="option">
            {{ 'ENUM.LANGUAGE.' + option.toUpperCase() | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </rb-form-field>
    <rb-form-field [label]="'APP.CLIENTS.CLIENT_INFO.PERSONAL_INFO.PHONE' | translate" class="col-start-2 row-start-3">
      <mat-form-field [class.no-error]="!validatorService.errorMatches(form.controls.phone, errorKey$ | async)">
        <input (blur)="onTouched()"
               [placeholder]="'APP.CLIENTS.CLIENT_INFO.PERSONAL_INFO.PHONE' | translate"
               formControlName="phone"
               matInput /></mat-form-field>
    </rb-form-field>
  </div>
  <div class="col-start-2 grid grid-cols-3 grid-rows-3 gap-x-[1rem] gap-y-[0.5rem]">
    <rb-form-field [label]="'APP.CLIENTS.CLIENT_INFO.PERSONAL_INFO.STREET' | translate"
                   class="col-start-1 col-span-2 row-start-1">
      <mat-form-field [class.no-error]="!validatorService.errorMatches(form.controls.street, errorKey$ | async)">
        <input (blur)="onTouched()"
               [placeholder]="'APP.CLIENTS.CLIENT_INFO.PERSONAL_INFO.STREET' | translate"
               formControlName="street"
               matInput
               type="text" />
      </mat-form-field>
    </rb-form-field>
    <rb-form-field [label]="'APP.CLIENTS.CLIENT_INFO.PERSONAL_INFO.NUMBER' | translate" class="col-start-3 row-start-1">
      <mat-form-field [class.no-error]="!validatorService.errorMatches(form.controls.number, errorKey$ | async)">
        <input (blur)="onTouched()"
               [placeholder]="'APP.CLIENTS.CLIENT_INFO.PERSONAL_INFO.NUMBER' | translate"
               formControlName="number"
               matInput
               type="text" />
      </mat-form-field>
    </rb-form-field>
    <rb-form-field [label]="'APP.CLIENTS.CLIENT_INFO.PERSONAL_INFO.POSTALCODE' | translate"
                   class="col-start-1 row-start-2">
      <mat-form-field [class.no-error]="!validatorService.errorMatches(form.controls.postalCode, errorKey$ | async)">
        <input (blur)="onTouched()"
               [placeholder]="'APP.CLIENTS.CLIENT_INFO.PERSONAL_INFO.POSTALCODE' | translate"
               formControlName="postalCode"
               matInput
               type="text" />
      </mat-form-field>
    </rb-form-field>
    <rb-form-field [label]="'APP.CLIENTS.CLIENT_INFO.PERSONAL_INFO.CITY' | translate"
                   class="col-start-2 col-span-2 row-start-2">
      <mat-form-field [class.no-error]="!validatorService.errorMatches(form.controls.municipality, errorKey$ | async)">
        <input (blur)="onTouched()"
               [placeholder]="'APP.CLIENTS.CLIENT_INFO.PERSONAL_INFO.CITY' | translate"
               formControlName="municipality"
               matInput
               type="text" />
      </mat-form-field>
    </rb-form-field>
    <rb-form-field [label]="'APP.CLIENTS.CLIENT_INFO.PERSONAL_INFO.EMAIL' | translate"
                   class="col-start-1 col-span-3 row-start-3">
      <mat-form-field [class.no-error]="!validatorService.errorMatches(form.controls.email, errorKey$ | async)">
        <input (blur)="onTouched()"
               [placeholder]="'APP.CLIENTS.CLIENT_INFO.PERSONAL_INFO.EMAIL' | translate"
               formControlName="email"
               matInput
               type="text" />
      </mat-form-field>
    </rb-form-field>
  </div>
</div>
