import { NgIf }    from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Directive,
  Input,
}                  from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'rb-view-section',
  standalone: true,
  imports: [MatIcon, NgIf],
  templateUrl: './view-section.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewSectionComponent {
  @Input()
  title?: string;

  @Input()
  icon?: string;

  @Input()
  prefix?: string;

  @Input()
  border: boolean = true;
}

@Directive({
  selector: '[rbViewSectionButtons]',
  standalone: true,
})
export class ViewSectionButtonsDirective {
}
