export type SimpleCreditor = {
  name: string;
  address?: string;
  companyNumber?: string;
  iban?: string;
}

export type Creditor = SimpleCreditor & {
  id: string;
  position: number;
  counterpartName: string;
  remittanceInformation?: string;
  remittanceInformationType?: CreditorRemittanceInformationType;
  paymentByThirdParties?: number;
  retainedAmount?: number;
  periodicAmountToPay?: number;
  mainSum?: number;
  totalDebt?: number;
  interest?: number;
  costs?: number;
  courtCosts?: number;
  increases?: number;
};

export type CreditorFormData = {
  id: string | null;
  position: number;
  name: string;
  address: string | null;
  companyNumber: string | null;
  iban: string | null;
  remittanceInformation: string | null;
  remittanceInformationType: CreditorRemittanceInformationType;
  totalDebt: number | null;
  mainSum: number | null;
  interest: number | null;
  costs: number | null;
  courtCosts: number | null;
  increases: number | null;
  retainedAmount: number | null;
  paymentByThirdParties: number | null;
};

export enum CreditorRemittanceInformationType {
  structured = 'STRUCTURED',
  unstructured = 'UNSTRUCTURED',
}

export type CreditorPaymentInfoResponse = {
  paymentDates: string[];
  creditorPaymentInfo: CreditorPaymentInfo[];
  total: TotalPaymentInfo;
};

export type CreditorPaymentInfo = {
  position: number;
  name: string;
  retainedAmount?: number;
  remainingDebt?: number;
  paymentByThirdParties?: number;
} & PaymentInfo;

export type TotalPaymentInfo = {
  retainedAmount: number;
  remainingDebt: number;
  paymentByThirdParties: number;
} & PaymentInfo;

export type PaymentInfo = {
  payments: Record<string, number>;
};
