<div class="flex flex-col gap-y-[4rem] min-h-[51rem]">
  <div class="grid grid-cols-2 gap-x-[4rem] place-items-start">
    <rb-form-section [title]="'APP.ADD_PAYMENT_DIALOG.FROM.HEADER' | translate" class="w-full">
      <div class="flex flex-col gap-y-[1rem]">
        <rb-form-field [label]="'APP.ADD_PAYMENT_DIALOG.FROM.CLIENT.TITLE' | translate" [required]="true">
          <mat-form-field [class.no-error]="!validatorService.errorMatches(form.controls.clientId, errorKey$ | async)"
                          class="w-full multi-line">
            <mat-select (selectionChange)="onClientChanged()"
                        [formControl]="form.controls.clientId"
                        class="flex items-center h-[4rem]"
                        placeholder="{{ 'APP.ADD_PAYMENT_DIALOG.FROM.CLIENT.NO_CLIENT' | translate }}"
                        required>
              <mat-select-trigger>
                <div *ngIf="selectedClient">
                  <ng-container *ngTemplateOutlet="clientOption; context: { client: selectedClient }"></ng-container>
                </div>
              </mat-select-trigger>
              <mat-option>
                <ngx-mat-select-search [closeSvgIcon]="'cross'"
                                       [formControl]="clientsSearch"
                                       [noEntriesFoundLabel]="'SELECT_FIELD.NOT_FOUND' | translate"
                                       [placeholderLabel]="'SELECT_FIELD.SEARCH' | translate"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let client of filteredClients$ | async" [value]="client.id" class="min-h-[5rem]">
                <ng-container *ngTemplateOutlet="clientOption; context: { client: client }"></ng-container>
              </mat-option>
              <ng-template #clientOption let-client="client">
                <rb-client-details [client]="client"></rb-client-details>
              </ng-template>
            </mat-select>
          </mat-form-field>
        </rb-form-field>
        <rb-form-field [label]="'APP.ADD_PAYMENT_DIALOG.FROM.ACCOUNT.TITLE' | translate" [required]="true">
          <mat-form-field [class.no-error]="!validatorService.errorMatches(form.controls.accountId, errorKey$ | async)"
                          class="w-full multi-line">
            <mat-select (selectionChange)="onAccountChanged()"
                        [formControl]="form.controls.accountId"
                        class="flex items-center h-[4rem]"
                        placeholder="{{ 'APP.ADD_PAYMENT_DIALOG.FROM.ACCOUNT.NO_ACCOUNT' | translate }}"
                        required>
              <mat-select-trigger>
                <div *ngIf="selectedAccount">
                  <ng-container *ngTemplateOutlet="accountOption; context: { account: selectedAccount }"></ng-container>
                </div>
              </mat-select-trigger>
              <mat-option>
                <ngx-mat-select-search [closeSvgIcon]="'cross'"
                                       [formControl]="accountsSearch"
                                       [noEntriesFoundLabel]="'SELECT_FIELD.NOT_FOUND' | translate"
                                       [placeholderLabel]="'SELECT_FIELD.SEARCH' | translate"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let account of filteredAccounts$ | async"
                          [value]="account.id"
                          class="min-h-[5rem]">
                <ng-container *ngTemplateOutlet="accountOption; context: { account: account }"></ng-container>
              </mat-option>
              <ng-template #accountOption let-account="account">
                <rb-bank-account-details [bankAccount]="account" [showType]="true"></rb-bank-account-details>
              </ng-template>
            </mat-select>
          </mat-form-field>
        </rb-form-field>
      </div>
    </rb-form-section>
    <rb-form-section [isLoading]="beneficiariesLoading === true"
                     [title]="'APP.ADD_PAYMENT_DIALOG.TO.HEADER' | translate"
                     class="w-full">
      <rb-edit-beneficiary (cancel)="cancelEditBeneficiary()"
                           (confirm)="confirmEditBeneficiary($event)"
                           (validationErrors)="onEditBeneficiaryError($event)"
                           *ngIf="editBeneficiaryEnabled"
                           [beneficiaries]="beneficiaries"
                           [beneficiaryEditing]="beneficiaryEditing"
                           [beneficiary]="form.controls.beneficiary.value"
      ></rb-edit-beneficiary>
      <div *ngIf="!editBeneficiaryEnabled" class="flex flex-col gap-y-[1rem]">
        <rb-form-field [label]="'APP.ADD_PAYMENT_DIALOG.TO.BENEFICIARY.TITLE' | translate" [required]="true">
          <mat-form-field [class.no-error]="!validatorService.errorMatches(form.controls.beneficiary, errorKey$ | async)"
                          class="w-full multi-line">
            <mat-select [formControl]="form.controls.beneficiary"
                        class="flex items-center h-[4rem]"
                        placeholder="{{ 'APP.ADD_PAYMENT_DIALOG.TO.BENEFICIARY.NO_BENEFICIARY' | translate }}">
              <mat-select-trigger>
                <div *ngIf="form.controls.beneficiary.value !== null">
                  <ng-container
                    *ngTemplateOutlet="beneficiaryOption; context: { beneficiary: form.controls.beneficiary.value }"></ng-container>
                </div>
              </mat-select-trigger>
              <mat-option>
                <ngx-mat-select-search [closeSvgIcon]="'cross'"
                                       [formControl]="beneficiariesSearch"
                                       [noEntriesFoundLabel]="'SELECT_FIELD.NOT_FOUND' | translate"
                                       [placeholderLabel]="'SELECT_FIELD.SEARCH' | translate"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngIf="form.controls.beneficiary.value?.singleUse"
                          [value]="form.controls.beneficiary.value">
                <ng-container
                  *ngTemplateOutlet="beneficiaryOption; context: { beneficiary: form.controls.beneficiary.value }"></ng-container>
              </mat-option>
              <mat-option *ngFor="let beneficiary of filteredBeneficiaries$ | async" [value]="beneficiary"
                          class="min-h-[5rem]">
                <ng-container *ngTemplateOutlet="beneficiaryOption; context: { beneficiary: beneficiary }"></ng-container>
              </mat-option>
              <mat-option (onSelectionChange)="addBeneficiary()" *ngIf="form.controls.clientId.value !== ''">
                <div class="flex gap-x-[1rem] items-center">
                  <mat-icon [svgIcon]="'plus'" class="icon-20 icon-brand mr-0"></mat-icon>
                  <span>{{ 'APP.ADD_PAYMENT_DIALOG.TO.BENEFICIARY.ADD_BENEFICIARY' | translate }}</span>
                </div>
              </mat-option>
              <ng-template #beneficiaryOption let-beneficiary="beneficiary">
                <div class="flex gap-x-[1rem] items-center">
                  <mat-icon [svgIcon]="getBeneficiaryIcon(beneficiary)" class="icon-20 icon-brand mr-0"></mat-icon>
                  <div class="flex flex-col">
                    {{ beneficiary.counterpartName }}
                    <div class="text-secondary text-sm">
                      {{ beneficiary.counterpartReference | split: { size: 4 } }}
                    </div>
                  </div>
                </div>
              </ng-template>
            </mat-select>
          </mat-form-field>
        </rb-form-field>
        <div class="flex justify-end">
          <button (click)="editBeneficiary()"
                  *ngIf="form.controls.beneficiary.value?.singleUse || form.controls.beneficiary.value?.type ===
                  BeneficiaryType.BENEFICIARY"
                  class="button-primary" mat-button>
            <mat-icon svgIcon="edit"></mat-icon>
            {{ 'APP.BUTTON.EDIT.LABEL' | translate }}
          </button>
          <div *ngIf="form.controls.beneficiary.value?.type === BeneficiaryType.CREDITOR"
               class="text-secondary text-sm">
            {{ 'APP.ADD_PAYMENT_DIALOG.TO.BENEFICIARY.NO_EDIT_CREDITOR' | translate }}
          </div>
          <div *ngIf="form.controls.beneficiary.value?.type === BeneficiaryType.BANK_ACCOUNT"
               class="text-secondary text-sm">
            {{ 'APP.ADD_PAYMENT_DIALOG.TO.BENEFICIARY.NO_EDIT_BANK_ACCOUNT' | translate }}
          </div>
        </div>
      </div>
    </rb-form-section>
  </div>
  <rb-form-section [title]="'APP.ADD_PAYMENT_DIALOG.DATA.HEADER' | translate">
    <div class="flex gap-x-[1rem]">
      <rb-form-field [label]="'APP.ADD_PAYMENT_DIALOG.DATA.AMOUNT.TITLE' | translate" [required]="true"
                     class="w-[20rem]">
        <mat-form-field [class.no-error]="!validatorService.errorMatches(form.controls.amount,
              errorKey$ | async)"
                        class="w-full">
          <mat-icon matPrefix svgIcon="euro"></mat-icon>
          <input [formControl]="form.controls.amount"
                 matInput
                 min="0"
                 placeholder="-"
                 required
                 type="number" />
        </mat-form-field>
      </rb-form-field>
      <rb-form-field [label]="'APP.ADD_PAYMENT_DIALOG.DATA.REMITTANCE_INFO.TITLE' | translate"
                     class="flex-1">
        <mat-form-field [class.no-error]="!validatorService.errorMatches(form.controls.remittanceInformation, errorKey$ | async)">
          <input *ngIf="!structured"
                 [formControl]="form.controls.remittanceInformation"
                 [placeholder]="'APP.ADD_PAYMENT_DIALOG.DATA.REMITTANCE_INFO.TITLE' | translate"
                 matInput
                 type="text" />
          <span *ngIf="structured" class="mono select-none text-secondary" matTextPrefix>+++</span>
          <div *ngIf="structured"
               class="absolute mono select-none text-secondary">&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+++
          </div>
          <input *ngIf="structured"
                 [formControl]="form.controls.remittanceInformation"
                 class="absolute mono"
                 matInput
                 rbStructuredRemittance
                 type="text" />
          <button (click)="toggleRemittanceInformationType()"
                  mat-icon-button
                  matSuffix>
            <mat-icon [svgIcon]="structured ? 'remittance-unstructured' : 'remittance-structured'"></mat-icon>
          </button>
        </mat-form-field>
      </rb-form-field>
      <rb-form-field [label]="'APP.ADD_PAYMENT_DIALOG.DATA.EXECUTION_DATE.TITLE' | translate"
                     class="w-[20rem]">
        <mat-form-field [class.no-error]="!validatorService.errorMatches(form.controls.executionDate, errorKey$ | async)">
          <input [formControl]="form.controls.executionDate"
                 [matDatepicker]="datePicker"
                 [min]="today"
                 matInput
                 placeholder="dd-mm-yyyy" />
          <mat-datepicker-toggle [for]="datePicker" matIconSuffix>
            <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #datePicker></mat-datepicker>
        </mat-form-field>
      </rb-form-field>
    </div>
  </rb-form-section>
  <div class="flex-1"></div>
  <div *ngIf="addPaymentEnabled">
    <mat-checkbox [formControl]="form.controls.anotherPayment">
      {{ 'APP.ADD_PAYMENT_DIALOG.ANOTHER_PAYMENT' | translate }}
    </mat-checkbox>
  </div>
</div>
