<div class="flex flex-col">
  <div class="flex justify-between">
    <h2 *ngIf="title || icon" class="flex items-center gap-x-[1rem] mb-[1rem]">
      <mat-icon *ngIf="icon" [svgIcon]="icon" class="icon-20 icon-brand"></mat-icon>
      <span *ngIf="title">{{ title }}</span>
    </h2>
    <ng-content select="[rbViewSectionButtons]"></ng-content>
  </div>
  <div *ngIf="prefix" class="mb-[1rem] text-sm text-secondary">{{ prefix }}</div>
  <div class="{{ border ? 'rounded-[0.5rem] border border-light' : '' }}">
    <ng-content></ng-content>
  </div>
</div>
