import {
  Language,
  UserRole,
} from '../enums';

export type AccountUser = {
  id: string,
  firstName: string,
  lastName: string,
  email: string,
  language: Language,
  officeId: string,
  roles: Array<UserRole | string>;
};

export type AccountUserFormData = {
  firstName: string,
  lastName: string,
  email: string,
};

export type OfficeUser = {
  id: string,
  firstName: string,
  lastName: string,
  email: string,
  blocked: boolean,
  role: UserRole,
  defaultAdministrator?: boolean,
  defaultDebtMediator?: boolean,
};

export type OfficeUserFilters = {
  showBlocked: boolean,
};

export type OfficeUserFormData = {
  firstName: string,
  lastName: string,
  email: string,
  isAdmin: boolean,
};

export type SimpleUser = {
  id: string,
  firstName: string,
  lastName: string,
  email?: string,
  defaultAdministrator?: boolean,
  defaultDebtMediator?: boolean,
};
