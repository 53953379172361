import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  ViewChild,
}                           from '@angular/core';
import {
  NonNullableFormBuilder,
  Validators,
}                           from '@angular/forms';
import { MAT_DIALOG_DATA }  from '@angular/material/dialog';
import {
  map,
  take,
}                           from 'rxjs';
import { ValidatorService } from '~app/core/validators';
import {
  ClientType,
  Language,
  officeAccountsEnabledForClientType,
}                           from '~domain/enums';
import {
  ClientData,
  ClientPersonalInfoFormData,
}                           from '~domain/types';
import {
  DialogButton,
  DialogComponent,
  DialogConfig,
}                           from '~shared/components';
import { RbDialogForm }     from '~shared/directives';
import { AddClientService } from './add-client.service';

@Component({
  selector: 'rb-add-client-dialog',
  templateUrl: './add-client-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddClientDialogComponent extends RbDialogForm implements OnInit {
  @ViewChild(DialogComponent)
  dialog?: DialogComponent;

  clientTypeOptions$ = this.addClientService.office$.pipe(
    map(office => office
      ? office.clientTypes.filter(type => (this.accountId ? officeAccountsEnabledForClientType(type) : true))
      : []),
  );
  defaultLanguage$ = this.addClientService.office$.pipe(
    map(office => (office ? office.language : Language.NL)),
  );
  createClientIsLoading$ = this.addClientService.createClientIsLoading$;

  override form = this.fb.group({
    personalInfoData: this.fb.control<ClientPersonalInfoFormData>({
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      ssn: '',
      birthDate: null,
      street: '',
      number: '',
      postalCode: '',
      municipality: '',
      language: null,
    }),
    type: this.fb.control<ClientType | null>(null, { validators: [Validators.required] }),
  });

  dialogConfig: DialogConfig = {
    title: 'APP.CLIENTS.ADD_CLIENT_DIALOG.TITLE',
    closeable: true,
    errorKey$: this.errorKey$,
    errorContext: 'APP.CLIENTS.CLIENT_INFO.EDIT.DIALOG.ERROR',
    buttons: [
      {
        id: DialogButton.Cancel,
        label: 'APP.CLIENTS.ADD_CLIENT_DIALOG.BUTTON.CANCEL.LABEL',
        icon: 'cross',
        style: 'secondary',
      },
      {
        id: DialogButton.OK,
        label: 'APP.CLIENTS.ADD_CLIENT_DIALOG.BUTTON.CONFIRM.LABEL',
        icon: 'check',
        style: 'primary',
        autoClose: false,
        disabled$: this.submitDisabled$,
        isLoading$: this.createClientIsLoading$,
      },
    ],
  };

  constructor(
    protected override readonly fb: NonNullableFormBuilder,
    private readonly addClientService: AddClientService,
    protected override readonly validatorService: ValidatorService,
    @Inject(MAT_DIALOG_DATA) public accountId?: string,
  ) {
    super(fb, validatorService);
  }

  ngOnInit() {
    this.clientTypeOptions$.pipe(take(1)).subscribe(types => {
      if (types.length === 1) {
        this.form.controls.type.setValue(types[0]);
      }
    });
  }

  onDialogButtonClick(dialogButton: DialogButton) {
    if (dialogButton === DialogButton.OK) {
      this.form.markAllAsTouched();
      if (this.form.valid) {
        const data = this.form.getRawValue() as ClientData;
        this.addClientService.createClient({ ...data, initialAccountId: this.accountId });
      }
    }
  }
}
