import {
  combineReducers,
  createReducer,
  on,
}                       from '@ngrx/store';
import {
  addBeneficiary,
  addPayment,
  addPaymentError,
  addPaymentResult,
  clearAccountUser,
  createClient,
  createClientError,
  createClientResult,
  deleteBeneficiary,
  downloadDocument,
  downloadDocumentError,
  downloadDocumentResult,
  editBeneficiariesError,
  editBeneficiariesResult,
  fetchAccountsWithWarningBadgeCountResult,
  fetchAccountUserResult,
  fetchBeneficiaries,
  fetchBeneficiariesError,
  fetchBeneficiariesResult,
  fetchClientBadgeCountResult,
  fetchColorModeResult,
  fetchInfoPaymentDialog,
  fetchInfoPaymentDialogError,
  fetchInfoPaymentDialogResult,
  fetchNotificationBadgeCountResult,
  fetchOfficeResult,
  fetchPaymentBadgeCountResult,
  fetchUnlinkedAccountsBadgeCountResult,
  respondToDocument,
  respondToDocumentError,
  respondToDocumentResult,
  setColorModeResult,
  updateBeneficiary,
  updatePayment,
  updatePaymentError,
  updatePaymentResult,
}                       from './app.actions';
import { initialState } from './app.state';

const colorModeReducer = createReducer(
  initialState.colorMode,
  on(fetchColorModeResult, (state, { colorMode }) => colorMode),
  on(setColorModeResult, (state, { colorMode }) => colorMode),
);

const accountUserReducer = createReducer(
  initialState.accountUser,
  on(fetchAccountUserResult, (state, { user }) => user),
  on(clearAccountUser, () => initialState.accountUser),
);

const clientBadgeCountReducer = createReducer(
  initialState.clientBadgeCount,
  on(fetchClientBadgeCountResult, (state, { count }) => count),
);

const notificationBadgeCountReducer = createReducer(
  initialState.notificationBadgeCount,
  on(fetchNotificationBadgeCountResult, (state, { count }) => count),
);

const paymentBadgeBadgeCountReducer = createReducer(
  initialState.paymentBadgeCount,
  on(fetchPaymentBadgeCountResult, (state, { count }) => count),
);

const unlinkedAccountsBadgeCountReducer = createReducer(
  initialState.unlinkedAccountsBadgeCount,
  on(fetchUnlinkedAccountsBadgeCountResult, (state, { count }) => count),
);

const accountsWithWarningBadgeCountReducer = createReducer(
  initialState.accountsWithWarningBadgeCount,
  on(fetchAccountsWithWarningBadgeCountResult, (state, { count }) => count),
);

const officeReducer = createReducer(
  initialState.office,
  on(fetchOfficeResult, (state, { office }) => office),
);

const createClientIsLoadingReducer = createReducer(
  initialState.createClientIsLoading,
  on(createClient, () => true),
  on(createClientResult, () => false),
  on(createClientError, () => false),
);

const documentUpdatingReducer = createReducer(
  initialState.documentUpdating,
  on(respondToDocument, () => true),
  on(respondToDocumentResult, () => false),
  on(respondToDocumentError, () => false),
);

const documentDownloadingIdReducer = createReducer(
  initialState.documentDownloadingId,
  on(downloadDocument, (state, { id }) => id),
  on(downloadDocumentResult, () => null),
  on(downloadDocumentError, () => null),
);

const paymentAccountsReducer = createReducer(
  initialState.paymentAccounts,
  on(fetchInfoPaymentDialogResult, (state, { paymentAccounts }) => paymentAccounts),
);

const paymentClientsReducer = createReducer(
  initialState.paymentClients,
  on(fetchInfoPaymentDialogResult, (state, { paymentClients }) => paymentClients),
);

const beneficiariesReducer = createReducer(
  initialState.beneficiaries,
  on(fetchInfoPaymentDialogResult, (state, { beneficiaries }) => beneficiaries),
  on(fetchBeneficiariesResult, (state, { beneficiaries }) => beneficiaries),
  on(editBeneficiariesResult, (state, { beneficiaries }) => beneficiaries),
);

const beneficiariesLoadingReducer = createReducer(
  initialState.beneficiariesLoading,
  on(fetchBeneficiaries, () => true),
  on(fetchBeneficiariesResult, () => false),
  on(fetchBeneficiariesError, () => false),
);

const paymentInfoLoadingReducer = createReducer(
  initialState.paymentInfoLoading,
  on(fetchInfoPaymentDialog, () => true),
  on(fetchInfoPaymentDialogResult, () => false),
  on(fetchInfoPaymentDialogError, () => false),
);

const paymentSavingReducer = createReducer(
  initialState.paymentSaving,
  on(addPayment, () => true),
  on(updatePayment, () => true),
  on(addPaymentResult, () => false),
  on(updatePaymentResult, () => false),
  on(addPaymentError, () => false),
  on(updatePaymentError, () => false),
);

const beneficiaryEditingReducer = createReducer(
  initialState.beneficiaryEditing,
  on(addBeneficiary, () => true),
  on(updateBeneficiary, () => true),
  on(deleteBeneficiary, () => true),
  on(editBeneficiariesError, () => false),
  on(editBeneficiariesResult, () => false),
);

export const appReducer = combineReducers(
  {
    colorMode: colorModeReducer,
    accountUser: accountUserReducer,
    clientBadgeCount: clientBadgeCountReducer,
    notificationBadgeCount: notificationBadgeCountReducer,
    paymentBadgeCount: paymentBadgeBadgeCountReducer,
    unlinkedAccountsBadgeCount: unlinkedAccountsBadgeCountReducer,
    accountsWithWarningBadgeCount: accountsWithWarningBadgeCountReducer,
    office: officeReducer,
    createClientIsLoading: createClientIsLoadingReducer,
    documentUpdating: documentUpdatingReducer,
    documentDownloadingId: documentDownloadingIdReducer,
    paymentAccounts: paymentAccountsReducer,
    paymentClients: paymentClientsReducer,
    beneficiaries: beneficiariesReducer,
    beneficiariesLoading: beneficiariesLoadingReducer,
    paymentInfoLoading: paymentInfoLoadingReducer,
    paymentSaving: paymentSavingReducer,
    beneficiaryEditing: beneficiaryEditingReducer,
  },
  initialState,
);
